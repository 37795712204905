import React, { useEffect, useState } from "react";

function Dashboard() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await fetch("http://localhost:4000/dashboard", {
          headers: { Authorization: `Bearer ${token}` },
        });

        const data = await response.json();
        if (response.ok) {
          setMessage(data.message);
        } else {
          alert(data.error);
          window.location.href = "/login";
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        window.location.href = "/login";
      }
    };

    fetchDashboardData();
  }, []);

  return <div>{message || "Loading..."}</div>;
}

export default Dashboard;
