import React from 'react';

function Header() {
  return (
    <header className="header">
      <h1>Welcome to The Thing</h1>
      <p>Secure and seamless user management system.</p>
    </header>
  );
}

export default Header;
