import DOMPurify from "dompurify";

export const isLoggedIn = () => {
  const token = localStorage.getItem("accessToken");
  if (!token) return false;

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return payload.exp * 1000 > Date.now();
  } catch (err) {
    console.error("Invalid token:", err);
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem("accessToken");
  window.location.href = "/login";
};

export const sanitizeInput = (input) => DOMPurify.sanitize(input);
